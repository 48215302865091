import { Fragment, SyntheticEvent, useCallback, useMemo } from "react"

import { WPKommuns } from "@/models"
import { useGlobalContext } from "@contexts/global"

import styles from "./Autocomplete.module.scss"

export interface AutocompleteProps {
  open: boolean
  query: string
  data: WPKommuns
  selected: string[]
  onChoose: (item: string) => void
}

export const Autocomplete = ({ open, query, data, selected, onChoose }: AutocompleteProps) => {
  const { newsletter } = useGlobalContext()
  const { noKommunsFound } = newsletter

  const isShowing = open && query.length >= 2
  const handler = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      const item = e.currentTarget.getAttribute("data-item")
      if (item) onChoose(item)
    },
    [onChoose]
  )
  const _groups = useMemo(() => {
    if (!isShowing) return []

    // filter kommuns by the given query
    const filtered = filterByQuery(query, data, selected)

    if (filtered.length > 0)
      return filtered.map(({ label, items }) => (
        <Fragment key={label}>
          <div className={styles.label}>{label}</div>
          {items.map(item => (
            <button key={item} className={styles.item} onClick={handler} data-item={item}>
              {item}
            </button>
          ))}
        </Fragment>
      ))

    // no found kommuns from the given filter
    return <div className={styles.empty}>{noKommunsFound}</div>
  }, [data, handler, isShowing, query, selected, noKommunsFound])

  if (!isShowing) return null

  return <div className={styles.container}>{_groups}</div>
}

function filterByQuery(query: string, data: WPKommuns, selected: string[]): WPKommuns {
  const pattern = new RegExp(`^${query}`, "i")
  const results: Record<string, string[]> = {}
  const keys: string[] = []

  for (const { label, items } of data) {
    // if query matches the group name
    // we return all of the items under that group
    if (pattern.test(label)) {
      keys.push(label)
      results[label] = items.filter(item => !selected.includes(item))
      continue
    }

    // otherwise, we only return items that matches the query
    for (const kommun of items) {
      if (!pattern.test(kommun) || selected.includes(kommun)) continue
      if (!keys.includes(label)) keys.push(label)

      if (typeof results[label] === "undefined") results[label] = [kommun]
      else results[label].push(kommun)
    }
  }

  // restructure data group
  return keys
    .map(key => [key, results[key]] as [string, string[]])
    .reduce((a, [label, items]) => [...a, { label, items }], [] as WPKommuns)
}
