import classNames from "classnames"
import { useRouter } from "next/router"
import { CSSProperties, SyntheticEvent } from "react"

import styles from "./Wysiwyg.module.scss"

export interface WysiwygProps {
  content: string
  className?: string
  style?: CSSProperties
}

export const Wysiwyg = ({ content, className, style }: WysiwygProps) => {
  const router = useRouter()
  const clickHandler = (e: SyntheticEvent) => {
    // intercept <a> clicks within the wysiwyg component
    // and properly handle internal links
    if (e.target instanceof HTMLAnchorElement) {
      const url = new URL(e.target.href)
      if (url.origin === window.location.origin) {
        e.preventDefault()
        router.push(url.pathname)
      }
    }
  }

  return (
    <div
      className={classNames(styles.container, className)}
      dangerouslySetInnerHTML={{ __html: content }}
      onClick={clickHandler}
      style={style}
    />
  )
}
