import Head from "next/head"
import { useRouter } from "next/router"

import { getSiteUrl } from "@/utils"

export interface MetadataProps {
  title: string
  description: string
  image: string
}

export const Metadata = ({ title, description, image }: MetadataProps) => {
  const router = useRouter()
  const _url = getSiteUrl(router.asPath, true)

  return (
    <Head>
      {/* SEO */}
      <title>{title}</title>
      <meta name="application-name" content={title} />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {_url && <meta property="og:url" content={_url} />}
      {_url && <link rel="canonical" href={_url} />}

      {/* Prevent search engines from indexing this page */}
      {process.env.NEXT_PUBLIC_NOINDEX === "noindex" && <meta name="robots" content="noindex" />}
    </Head>
  )
}
