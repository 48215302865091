import { Section } from "@components/section"
import { Wysiwyg } from "@components/wysiwyg"
import { useGlobalContext } from "@contexts/global"

import { RegistrationForm } from "./RegistrationForm"
import styles from "./RegistrationFormSection.module.scss"

export const RegistrationFormSection = () => {
  // retrieve static form data from global context
  const { newsletter } = useGlobalContext()
  const { formTitle, formDescription } = newsletter

  return (
    <Section id="form-interest" title={formTitle}>
      <Wysiwyg className={styles.formDescription} content={formDescription} />
      <RegistrationForm className={styles.form} />
      <div className={styles.filler} />
    </Section>
  )
}
