import classNames from "classnames"
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { WPKommuns } from "@/models"
import { useGlobalContext } from "@contexts/global"

import { Autocomplete } from "./Autocomplete"
import styles from "./RegistrationForm.module.scss"

export interface RegistrationFormProps {
  className?: string
}

export const RegistrationForm = ({ className }: RegistrationFormProps) => {
  // retrieve static form data from global context
  const { newsletter } = useGlobalContext()
  const {
    formSubtitle,
    nameLabel,
    namePlaceholder,
    emailLabel,
    emailPlaceholder,
    kommunLabel,
    kommunPlaceholder,
    kommunItems,
    tcText,
    submitLabel,
    submitLabelLoading,
    formSuccessMessage,
    formErrorMessage,
    formErrorKommun,
  } = newsletter

  // form state
  const refKommun = useRef<HTMLInputElement>(null)
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)
  const [kommuns, setKommuns] = useState<string[]>([])
  const [query, setQuery] = useState("")

  // form submit state
  const [message, setMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  // handlers
  const handleKommunClick = useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    const index = parseInt(e.currentTarget.getAttribute("data-index")!)
    setKommuns(kommuns => [...kommuns.slice(0, index), ...kommuns.slice(index + 1)])
  }, [])
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement

    // check if user has selected atleast one kommun
    if (kommuns.length === 0) {
      setMessage(formErrorKommun)
      return
    }

    // start submission process
    setLoading(true)
    setMessage(null)

    // build API request payload
    const formValues = new FormData(form)
    const data = Object.fromEntries(formValues.entries())
    const payload = { ...data, kommuns }

    // submit form data
    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })

      if (response.ok) {
        setMessage(formSuccessMessage)
        form.reset()
        setKommuns([])
      } else {
        setMessage(formErrorMessage)
      }
    } catch (e) {
      console.error(e)
      setMessage(formErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  // generate kommun buttons
  const _kommuns = useMemo(
    () =>
      kommuns.map((kommun, index) => (
        <span key={kommun} className={styles.kommun}>
          <span>{kommun}</span>
          <button
            className={styles.kommunClose}
            onClick={handleKommunClick}
            data-index={index}
          ></button>
        </span>
      )),
    [kommuns, handleKommunClick]
  )

  // show kommun autocompletion list on focus
  useEffect(() => {
    if (refKommun.current) {
      const refKommunCopy = refKommun.current
      const handleFocus = () => setAutoCompleteOpen(true)

      refKommunCopy.addEventListener("focus", handleFocus)
      return () => refKommunCopy.removeEventListener("focus", handleFocus)
    }
  }, [refKommun])

  // close the list when the escape key is pressed
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setAutoCompleteOpen(false)
        refKommun.current?.blur()
      }
    }
    window.addEventListener("keyup", handler)
    return () => window.removeEventListener("keyup", handler)
  }, [])

  // close the list when the user clicked on something other than the current field
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!refKommun.current?.parentNode?.contains(e.target as any)) {
        setAutoCompleteOpen(false)
      }
    }
    window.addEventListener("click", handler)
    return () => window.removeEventListener("click", handler)
  }, [])

  return (
    <div className={classNames(styles.container, className)}>
      <form onSubmit={handleSubmit}>
        <h3>{formSubtitle}</h3>

        <div className={styles.twoColumns}>
          <div className={styles.formInput}>
            <label htmlFor="fname">{nameLabel}</label>
            <input type="text" name="fname" id="fname" placeholder={namePlaceholder} required />
          </div>

          <div className={styles.formInput}>
            <label htmlFor="email">{emailLabel}</label>
            <input type="email" name="email" id="email" placeholder={emailPlaceholder} required />
          </div>
        </div>

        <div className={styles.formInput}>
          <label htmlFor="kommuns">{kommunLabel}</label>
          <input
            ref={refKommun}
            type="text"
            name="kommuns"
            id="kommuns"
            placeholder={kommunPlaceholder}
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          {autoCompleteOpen && (
            <button
              className={styles.close}
              onClick={(e: SyntheticEvent) => {
                e.preventDefault()
                setAutoCompleteOpen(false)
                setQuery("")
              }}
            ></button>
          )}
          <Autocomplete
            open={autoCompleteOpen}
            query={query}
            data={kommunItems as WPKommuns}
            selected={kommuns}
            onChoose={item => {
              if (!kommuns.includes(item)) setKommuns(kommuns => [...kommuns, item])
              setAutoCompleteOpen(false)
              setQuery("")
            }}
          />
        </div>

        {/*  user-selected kommuns */}
        <div className={styles.kommuns}>{_kommuns}</div>

        <div className={styles.footer}>
          <div className={styles.formGdpr}>
            <input type="checkbox" name="gdpr" id="gdpr" required />
            <label htmlFor="gdpr" dangerouslySetInnerHTML={{ __html: tcText }}></label>
          </div>
          <div className={styles.actions}>
            <button type="submit" disabled={loading}>
              {loading ? submitLabelLoading : submitLabel}
            </button>
          </div>
        </div>

        {/* Form submit feedback */}
        {message && <div className={styles.formFeedback}>{message}</div>}
      </form>
    </div>
  )
}
