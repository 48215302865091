import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"

import { AnimatedIcon } from "./AnimatedIcon"
import styles from "./RegisterFab.module.scss"

export interface RegisterFabProps {
  label?: string | null
}

export const RegisterFab = ({ label }: RegisterFabProps) => {
  const router = useRouter()
  const ref = useRef<HTMLButtonElement>(null)
  const [section, setSection] = useState<HTMLElement | null>(null)

  // check DOM if signup form is present
  useEffect(() => {
    // define handler and run handler atleast once
    const handler = () => setSection(document.getElementById("form-interest"))
    handler()

    // subscribe to route changes to re-run handler
    router.events.on("routeChangeComplete", handler)
    router.events.on("routeChangeError", handler)

    // unsubscribe to route changes
    return () => {
      router.events.off("routeChangeComplete", handler)
      router.events.off("routeChangeError", handler)
    }
  }, [router])

  // adjust bottom offset when cookie banner is present
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const targetHeight = entry.borderBoxSize[0].blockSize
        if (targetHeight > 0) {
          ref.current?.style.setProperty("--bottom-offset", `${targetHeight + 10}px`)
        } else {
          ref.current?.style.removeProperty("--bottom-offset")
        }
      })
    })
    const timeout = setTimeout(() => {
      const target = document.querySelector(".app-cookie-banner")
      if (target) observer.observe(target)
    })

    return () => {
      clearTimeout(timeout)
      observer.disconnect()
    }
  }, [])

  // skip render if form is not present
  if (!label || !section) return null

  return (
    <button
      ref={ref}
      className={styles.container}
      onClick={() => section.scrollIntoView({ behavior: "smooth" })}
    >
      <span className={styles.label}>{label}</span>
      <AnimatedIcon />
    </button>
  )
}
