import classNames from "classnames"
import { CSSProperties, ReactNode } from "react"

import { WPLink } from "@/models"
import { AppLink } from "@components/app-link"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./Section.module.scss"
import { useResponsiveMD } from "@hooks/shared"

export interface SectionProps {
  label?: string | ReactNode
  title?: string
  link?: WPLink
  description?: string
  variant?: "light" | "lighter"
  id?: string
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

export const Section = ({
  label,
  title,
  link,
  description,
  variant,
  id,
  className,
  style,
  children,
}: SectionProps) => {
  const isResponsiveMD = useResponsiveMD()
  const sectionClasses = classNames(styles.container, className, {
    [styles.light]: variant === "light",
    [styles.lighter]: variant === "lighter",
  })

  return (
    <section id={id} className={sectionClasses} style={style}>
      <div className={styles.wrapper}>
        {typeof label === "string" ? (
          <div className={styles.labelContainer}>
            <h2 className={styles.label}>{label}</h2>
          </div>
        ) : (
          label
        )}

        {(title || link) && (
          <div
            className={classNames(styles.titleWrapper, {
              [styles.hasLabel]: typeof label === "string",
              [styles.hasLink]: !!link,
            })}
          >
            <h3 className={styles.title}>{title}</h3>
            {isResponsiveMD && link && <AppLink {...link} />}
          </div>
        )}

        {description && <Wysiwyg className={styles.description} content={description} />}
        {children}
        {!isResponsiveMD && link && (
          <div className={styles.mobileLinkWrapper}>
            <AppLink {...link} />
          </div>
        )}
      </div>
    </section>
  )
}
