import { serialize } from "cookie"
import { ImageProps } from "next/legacy/image"

import type { AppLocale, WPKommuns, WPMedia } from "./models"

export const resize = (
  image: WPMedia,
  options?: { height?: number; width?: number }
): ImageProps => {
  let width = image.mediaDetails?.width
  let height = image.mediaDetails?.height

  // resize - width and height is defined
  if (options?.width && options?.height) {
    width = options.width
    height = options.height
  }
  // resize - only width is defined
  else if (options?.width) {
    let oldWidth = width
    width = options.width

    if (Number.isFinite(oldWidth) && Number.isFinite(height)) {
      height = (options.width * height!) / oldWidth!
    }
  }
  // resize - only height is defined
  else if (options?.height) {
    let oldHeight = height
    height = options.height

    if (Number.isFinite(width) && Number.isFinite(oldHeight)) {
      width = (options.height * width!) / oldHeight!
    }
  }

  return {
    src: image.mediaItemUrl,
    alt: image.altText,
    width,
    height,
  }
}

export const isEmptyObject = (obj: Record<any, any>) => {
  for (var _ in obj) return false
  return true
}

export const parseKommuns = (value: string): WPKommuns => {
  const kommuns: Record<string, string[]> = {}
  const lines = value.split(/\r?\n/)
  const keys: string[] = []

  let lastCode: string | null = null
  let lastKey: string | null = null
  for (const line of lines) {
    const [code, kommun] = line.split(",")

    // current row is a heading
    if (code.length === 2) {
      keys.push(kommun)
      lastCode = code
      lastKey = kommun
      continue
    }

    // current row is a kommun that belongs to the kommun
    if (lastCode && lastKey && code.startsWith(lastCode)) {
      if (typeof kommuns[lastKey] === "undefined") {
        kommuns[lastKey] = [kommun]
      } else {
        kommuns[lastKey].push(kommun)
      }
    }
  }

  // restructure data group
  return keys
    .map(key => [key, kommuns[key]] as [string, string[]])
    .reduce((a, [label, items]) => [...a, { label, items }], [] as WPKommuns)
}

/**
 * Checks the given access token for its validity.
 *
 * @param accessToken The access token
 * @returns true if the access token is still valid, false otherwise
 */
export const isAuthValid = (accessToken: string): boolean => {
  if (typeof accessToken !== "string" || accessToken.length === 0) return false

  // split into three parts (header, payload, signature)
  const parts = accessToken.split(".", 3)
  if (parts.length !== 3) return false

  // base-64 decode payload
  const rawPayload = Buffer.from(parts[1], "base64").toString("utf-8")
  const payload = JSON.parse(rawPayload)
  const realAccessToken = payload.data?.login_token
  if (typeof realAccessToken !== "string" || realAccessToken.length === 0) return false

  // split into three parts again
  const _parts = realAccessToken.split(".", 3)
  if (_parts.length !== 3) return false

  // decode real access token's payload
  const _rawPayload = Buffer.from(_parts[1], "base64").toString("utf-8")
  const { gracePeriod, exp } = JSON.parse(_rawPayload)
  if (typeof exp !== "number") return false

  return Date.now() / 1000 - exp < gracePeriod
}

/**
 * Returns the grace period of the given access token (in seconds).
 *
 * @param accessToken the access token to inspect
 * @param defaultValue the default grace period (in seconds)
 * @returns the grace period of the given access token (in seconds).
 */
export const getGracePeriod = (accessToken: string, defaultValue = 1800): number => {
  if (typeof accessToken !== "string" || accessToken.length === 0) return defaultValue

  // split into three parts (header, payload, signature)
  const parts = accessToken.split(".", 3)
  if (parts.length !== 3) return defaultValue

  // base-64 decode payload
  const rawPayload = Buffer.from(parts[1], "base64").toString("utf-8")
  const payload = JSON.parse(rawPayload)
  const realAccessToken = payload.data?.login_token
  if (typeof realAccessToken !== "string" || realAccessToken.length === 0) return defaultValue

  // split into three parts again
  const _parts = realAccessToken.split(".", 3)
  if (_parts.length !== 3) return defaultValue

  // decode real access token's payload
  const _rawPayload = Buffer.from(_parts[1], "base64").toString("utf-8")
  const { gracePeriod } = JSON.parse(_rawPayload)

  return gracePeriod
}

/**
 * Normalizes locale selection with SE as the fallback
 * @param locale can be null/undefined/unknown
 * @returns AppLocale (se/en)
 */
export const getAppLocale = (locale?: string | null): AppLocale => {
  if (locale === "en" || locale === "EN" || locale === "en_US") return "en"
  return "se"
}

/**
 * Sets a cookie on the browser that is readable by javascript
 */
export const setBrowserCookie = (key: string, value: string) => {
  if (typeof document === "undefined")
    throw new Error(`setBrowserCookie should only be run on the browser`)

  // get host from window.location object
  const domain = window.location.host.endsWith("beta.bosam.se")
    ? "beta.bosam.se"
    : window.location.host.endsWith("bosam.se")
    ? "bosam.se"
    : "localhost"

  // add cookie to browser
  document.cookie = serialize(key, value, { domain, path: "/" })
}

export const getSiteUrl = (path: string, removeQueryParams = true) => {
  // detect origin based on the current environment
  let origin = typeof window !== "undefined" ? window.location.origin : process.env.APP_ORIGIN
  let url = new URL(path, origin).toString()

  // remove query parameters (optional)
  if (removeQueryParams) {
    let qIndex = url.indexOf("?")
    if (qIndex !== -1) url = url.slice(0, qIndex)
  }

  // make sure that url ends with a trailing slash
  if (url.charAt(url.length - 1) !== "/") url = url + "/"

  return url
}
