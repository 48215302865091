import classNames from "classnames"
import { CSSProperties } from "react"

import { AppLink, AppLinkProps } from "./AppLink"
import styles from "./AppLinks.module.scss"

export interface AppLinksProps {
  links?: AppLinkProps[]
  link?: AppLinkProps
  className?: string
  style?: CSSProperties
}

export const AppLinks = ({ links, link, className, style }: AppLinksProps) => {
  const elLinks =
    Array.isArray(links) && links.length > 0 ? (
      links.map((link) => <AppLink key={link.label} {...link} />)
    ) : link ? (
      <AppLink {...link} />
    ) : null

  return (
    elLinks && (
      <div className={classNames(styles.links, className)} style={style}>
        {elLinks}
      </div>
    )
  )
}
