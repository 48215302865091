import classNames from "classnames"
import mime from "mime-types"
import Image from "next/legacy/image"
import Link from "next/link"
import { SyntheticEvent, useCallback } from "react"

import arrowDown from "@/public/images/arrow-down.svg"
import arrowRight from "@/public/images/arrow-right.svg"
import iconAdd from "@/public/images/icon-add.svg"
import iconDownload from "@/public/images/icon-download.svg"

import styles from "./AppLink.module.scss"

export type AppLinkVariant = "default" | "green" | "unstyled"
export type AppLinkIcon = "-" | "arrow-down" | "arrow-right" | "add" | "download"

export interface AppLinkProps {
  label: string
  variant?: AppLinkVariant
  icon?: AppLinkIcon
  href?: string
  classes?: string
  className?: string
}

export const AppLink = ({
  label,
  variant,
  icon,
  href,
  classes: linkExtraClasses,
  className,
}: AppLinkProps) => {
  const iconSrc =
    icon === "arrow-down"
      ? arrowDown
      : icon === "arrow-right"
      ? arrowRight
      : icon === "add"
      ? iconAdd
      : icon === "download"
      ? iconDownload
      : null
  const classes = classNames(linkExtraClasses, styles.link, className, {
    [styles.unstyled]: variant === "unstyled",
    [styles.linkGreen]: variant === "green",
    [styles.hasIcon]: iconSrc !== null,
  })
  const needsNextLink = typeof href === "string" && href.startsWith("/")

  const clickHandler = useCallback(
    (e: SyntheticEvent) => {
      // scroll to a section in the current page
      if (typeof href === "string" && href.startsWith("#")) {
        e.preventDefault()

        // scroll to target section
        try {
          const targetSection = document.querySelector(href)
          if (!targetSection) return
          targetSection.scrollIntoView({ behavior: "smooth" })
        } catch (_) {}
      }
      // download the file if href points to a file
      else if (typeof href === "string" && mime.lookup(href)) {
        e.preventDefault()

        // download file
        const encodedURL = encodeURIComponent(href)
        window.location.href = `/api/download?url=${encodedURL}`
      }
      // open external link in a new tab
      else if (
        typeof href === "string" &&
        (href.startsWith("http://") || href.startsWith("https://")) &&
        new URL(href).host !== window.location.host
      ) {
        e.preventDefault()
        window.open(href, "_blank", "noopener,noreferrer")
      }
    },
    [href]
  )
  const container = (
    <a className={classes} href={href} onClick={clickHandler}>
      <span className={styles.label}>{label}</span>
      {iconSrc && <Image className={styles.icon} src={iconSrc} width="24" height="24" alt="icon" />}
    </a>
  )

  // 3 different types of links
  // - http:// or https://      - NO NEED <Link> - default behavior
  // - /<internal-page-name>    - NEED <Link>    - default behavior
  // - #<same-page-section-id>  - NO NEED <Link> - scroll to section

  return needsNextLink ? (
    <Link href={href} legacyBehavior>
      {container}
    </Link>
  ) : (
    container
  )
}
