import { withPasswordProtect } from "next-password-protect"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import { RegisterFab } from "@components/fab-register"
import { GtmScript } from "@components/metadata"
import { ProgressBar } from "@components/progress-bar"

import "/styles/cookiebot.scss"
import "/styles/globals.scss"
import "slick-carousel/slick/slick.css"

function BosamApp({ Component, pageProps }: AppProps) {
  const [isAnimating, setIsAnimating] = useState(false)
  const router = useRouter()

  // render floating newsletter button
  const fabLabel = pageProps.hasOwnProperty("globalFields")
    ? (pageProps as any).globalFields?.acfGlobalFields?.acfGlobal?.newsletter?.fabLabel
    : null

  // add custom route change animation
  useEffect(() => {
    const handleStart = () => setIsAnimating(true)
    const handleStop = () => setIsAnimating(false)

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
    }
  }, [router])

  return (
    <>
      <ProgressBar isAnimating={isAnimating} />
      <Component {...pageProps} />
      <RegisterFab label={fabLabel} />
      <GtmScript gtmId="GTM-5Z53V7Q" />
    </>
  )
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(BosamApp, {
      loginApiUrl: "/api/beta-login",
      checkApiUrl: "/api/beta-password-check",
    })
  : BosamApp
