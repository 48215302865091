import classNames from "classnames"

import styles from "./AnimatedIcon.module.scss"

export interface AnimatedIconProps {
  open?: boolean
}

export const AnimatedIcon = ({ open }: AnimatedIconProps) => {
  return (
    <span className={classNames(styles.container, { [styles.open]: open })}>
      <span className={styles.handle}></span>
      <span className={styles.handle}></span>
    </span>
  )
}
